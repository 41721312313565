import { render, staticRenderFns } from "./PdfSchemaFormRadioGroup.vue?vue&type=template&id=39652ca4&scoped=true"
import script from "./PdfSchemaFormRadioGroup.vue?vue&type=script&lang=js"
export * from "./PdfSchemaFormRadioGroup.vue?vue&type=script&lang=js"
import style0 from "./PdfSchemaFormRadioGroup.vue?vue&type=style&index=0&id=39652ca4&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39652ca4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39652ca4')) {
      api.createRecord('39652ca4', component.options)
    } else {
      api.reload('39652ca4', component.options)
    }
    module.hot.accept("./PdfSchemaFormRadioGroup.vue?vue&type=template&id=39652ca4&scoped=true", function () {
      api.rerender('39652ca4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormRadioGroup.vue"
export default component.exports