<template>
  <b-form-radio-group
    class="resource-radio stageline-schema-form-radio-group"
    :checked="formData[fields[0].id]"
  >
    <b-form-radio
      v-for="button in fields"
      :key="button.id"
      v-model="formData[button.id]"
      class="tab-order-schema-radio sortable-field-container"
      :tabindex="schemaTabIndex(button.id)"
      :disabled="isDisabledFieldComponent(button.id)"
      :value="button.data.button_value"
      @keydown.enter.prevent="handleEnterPressed"
      @focus="setCurrentFieldById(button.id)"
    >
      {{ button.data.button_value }}
    </b-form-radio>
  </b-form-radio-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PdfSchemaFormRadioGroup',
  components: {
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'formData',
      'isDisabledField',
      'schemaTabIndex',
    ]),
    title() {
      let label = 'Please select one of the following options: '

      this.fields.forEach(field => {
        if (field.title) {
          label = field.title
        }
      })

      return label
    },
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'setCurrentFieldById',
    ]),
    isDisabledFieldComponent(fieldId) {
      return this.isDisabledField(this.fields.find(f => f.id === fieldId))
    },
    handleEnterPressed(e) {
      this.$emit('enterPressed', e.shiftKey ? 'prev' : 'next')
    },
  },
}
</script>

<style scoped lang="scss">
@media only screen and (max-width: 1024px) {
  .stageline-schema-form-radio-group {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}
</style>
