var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-radio-group",
    {
      staticClass: "resource-radio stageline-schema-form-radio-group",
      attrs: { checked: _vm.formData[_vm.fields[0].id] },
    },
    _vm._l(_vm.fields, function (button) {
      return _c(
        "b-form-radio",
        {
          key: button.id,
          staticClass: "tab-order-schema-radio sortable-field-container",
          attrs: {
            tabindex: _vm.schemaTabIndex(button.id),
            disabled: _vm.isDisabledFieldComponent(button.id),
            value: button.data.button_value,
          },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.handleEnterPressed.apply(null, arguments)
            },
            focus: function ($event) {
              return _vm.setCurrentFieldById(button.id)
            },
          },
          model: {
            value: _vm.formData[button.id],
            callback: function ($$v) {
              _vm.$set(_vm.formData, button.id, $$v)
            },
            expression: "formData[button.id]",
          },
        },
        [_vm._v("\n    " + _vm._s(button.data.button_value) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }